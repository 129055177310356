import * as Sentry from '@sentry/node'

import HomeView from '@/views/Home'

import { getCatalogCategoryProducts } from '@/services/catalog/getCatalogProducts'
import { getProductsData } from '@/services/content/getProductsData'
import { getReviewsList } from '@/services/content/getReviewsList'
import CATEGORIES from '@/temp-configs/category-constants'
import { buildServerSideContext, buildServerSideProps } from '@/utils/buildServerSide'
import { getPrismicRef } from '@/utils/prismicPreview'
import baseRouter from '@/utils/baseRouter'
import { getAndCacheRecommendationList } from '@/services/cacheResponses/getAndCacheRecommendationList'
import { getExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

const router = baseRouter().get(async (req, res) => {
    const pageData = {
        title: 'Smarter Luxury Sleep',
        pageType: 'homepage',
        excludedScripts: [],
        products: [],
        recommendedProducts: [],
    }
    const productsCategory = CATEGORIES.MATTRESSES
    const prismicRef = getPrismicRef(req)

    const { isV1: enableRecStrip } = getExperiment(res.locals.experiments, 'EXP.HP_MATTRESS_TILES.DIS-271', res.locals.flagOverrides)

    try {
        const [catalogProducts, productsContent, reviewList] = await Promise.all([
            getCatalogCategoryProducts(productsCategory),
            getProductsData(productsCategory, prismicRef),
            getReviewsList('homepage', prismicRef)
        ])

        if (enableRecStrip) {
            pageData.recommendedProducts = await getAndCacheRecommendationList('homepage-mattress', prismicRef)
        }
        else {
            pageData.products = catalogProducts.map(product => {
                const content = productsContent?.find(({ sku }) => product.productCode === sku)

                if (content) {
                    product.content = {
                        ...product.content,
                        ...content
                    }
                }

                return product
            })
        }

        pageData.reviewList = reviewList
    } catch (error) {
        console.log(`[pages.home]: Error fetching products: ${error.message}`)
        Sentry.captureException(error)
    }

    return {
        props: buildServerSideProps(pageData, res.locals)
    }
})

export function getServerSideProps(context) {
    const { req, res } = buildServerSideContext(context)
    return router.run(req, res)
}

export default function HomePage(props) {
    return (
        <HomeView {...props} />
    )
}
